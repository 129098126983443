<template>
  <div>
	<!-- Self OKR Page-->
	<div class="individual-page">
		<div class="page-header">
      <div class="card col-xl-12 border-primary mb-0">
        <div class="card-body d-flex align-items-center justify-content-between p-50">
          <b-tabs>
            <b-tab active >
              <template #title >
                <span>My OKRs</span>
              </template>
            </b-tab>
            <!-- <b-tab @click="AddFilter('post')">
              <template #title>
                <span>Archive</span>
              </template>
            </b-tab> -->
          </b-tabs>
          <div class="">
				<!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" v-b-modal.new-goal-form>
					<feather-icon icon="PlusIcon" /> New OKR
				</b-button> -->
        <b-button-group>
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" v-b-modal.new-goal-form
            icon="PlusIcon"
            text="+ New OKR"
          >
            <b-dropdown-item v-b-modal.new-goal-form v-for="option in okrTypeList" :key="option.label" @click="addTypeSelected(option)"
              >{{option.label}}</b-dropdown-item
            >
          </b-dropdown>
        </b-button-group>
        <b-modal id="new-goal-form" centered size="xl" style="background-color: transparent;" ok-title="Submit" @ok.prevent="formSubmitted()" @shown="showOkrForm" no-close-on-backdrop>
          <template #modal-header="{ close }">
            <h5 class="mb-0 font-weight-bolder">What do you want to accomplish?</h5>
            <b-button size="sm" variant="outline-transparent" class="p-0" @click="close()">
              <feather-icon icon="XIcon" size="24" class="text-body align-middle ml-2"/>
            </b-button>
          </template>
          <h4 class="text-center pt-1 font-weight-bolder">We all have things to get done. No matter how big or small, it starts with a goal. </h4>
          <h5 class="text-center mb-2">So let’s get started!</h5>
          <b-form autocomplete="off" id="okr-form">
              <b-card class="border">
                <b-card-text class="d-flex align-items-center border-bottom-primary pb-1">
                  <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
                  <input ref="okrname" v-model="data_local.title" class="font-weight-bolder mb-0 ml-1 border-0 w-100" style="font-size:20px;" type="text" placeholder="Enter the OKR title here..." />
                  <!-- <h4 class="font-weight-bold mb-0 ml-1">OKR Title</h4> -->
                </b-card-text>
		<b-row>
                  <b-col md="6">
                    <b-form-group label="OKR Period Range" label-for="period_range" size="30">
                      <flat-pickr
                        ref="period_range"
                        class="form-control"
                        input-id="period_range"
                        placeholder="Select Start and End Date"
                        v-model="data_local.period_range"
                        @input="rangeChanged"
                        :config="dateConfig"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="OKR Type" label-for="okr_type">
                                <v-select
                                  :options="okrTypeList"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="okr_type"
                                  name="okr_type"
                                  placeholder="Select OKR Type"
                                  v-model="data_local.okr_type"
                                />
                              </b-form-group>
                  </b-col>
                  <b-col md="6" 
                  v-if="$can('Zircly Admin') || $can('Manager')">
                    <b-form-group label="OKR Owner" label-for="okr_owner">
                                <v-select
                                  :options="employeeOptions"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="okr_owner"
                                  name="okr_owner"
                                  placeholder="Select OKR Owner"
                                  v-model="data_local.okr_owner"
                                />
                              </b-form-group>
                  </b-col>
                </b-row>
                <b-alert show>
                  <b-row class="px-2 py-1">
                    <b-col cols="auto" class="d-none d-md-block">
                      <b-img-lazy fluid :src="require('@/assets/images/icons/idea.png')" alt="goal-img" width="30px" height="30px"/>
                    </b-col>
                    <b-col>
                      <p class="text-dark">Capture what you hope to accomplish with your goal. Try to keep it specific and objective-focussed. Struggling with a title? Skip this section for now and return to it later.</p>
                    </b-col>
                  </b-row>      
                </b-alert>
              </b-card>
              <b-card class="border obj-main">
                <div class="obj-header">
                  <b-row>
                    <b-col>
                  <h4>Define your Objectives and Key Results</h4>
                  <h5 class="small">Use this SMART framework to create effective okrs that keep you headed in the right direction.</h5>
                    </b-col>
                    <b-col sm="12" md="3" class="text-md-right">
                      <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="primary"
                      @click="add()"
                    >
                    <feather-icon icon="PlusIcon" 
                    size="15"/> Add Objective
                    </b-button>
                    </b-col>
                  </b-row>
                  <hr>
                </div>
                <b-row v-if="data_local.okr_details" class="obj-details">
                  <b-col md="12" v-for="(action_item,k) in data_local.okr_details" :key="k">
                    <div style="border: 2px solid #38B22D;padding: 20px;border-radius: 50px 20px;" class="mt-1 relative">
                      <b-button @click="remove(k)" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                              >
                              <feather-icon icon="TrashIcon" size="13" class="text-danger" 
                              v-b-tooltip.hover.v-danger
                              title="Remove Objective"/>
                            </b-button>
                      <h5 class="obj-title">  Objective {{k+1}} </h5>
                      <b-row>
                        <b-col sm="12" md="6">
                          <b-form-group label="Type" label-for="objective_type">
                            <v-select
                              :options="typeList"
                              label="label"
                              :reduce="(label) => label.code"
                              :clearable="true"
                              :searchable="true"
                              input-id="objective_type"
                              name="objective_type"
                              placeholder="Select OKR Type"
                              v-model="data_local.okr_details[k].type"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- <b-col sm="12" md="6">
                          <b-form-group label="Status" label-for="status">
                            <v-select
                              :options="statusList"
                              label="label"
                              :reduce="(label) => label.code"
                              :clearable="true"
                              :searchable="true"
                              input-id="status"
                              name="status"
                              placeholder="Select Status"
                              v-model="data_local.okr_details[k].status"
                            />
                          </b-form-group>
                          </b-col> -->
                        <b-col sm="12" md="12">
                          <b-form-group
                              label="Objective"
                              label-for="objective"
                            >
                            <b-form-textarea
                              id="objective"
                              placeholder="Enter the Objective in short"
                              v-model="data_local.okr_details[k].objective"
                              rows="2"
                              ref="lastAddedInput"
                            />
                            </b-form-group>
                        </b-col>
                        
                        <b-col sm="12" md="12">
                          <!-- <b-form-group
                            label="Key Result"
                            label-for="key_result"
                            icon="CheckSquareIcon"
                          > -->

                          <!-- <b-form-textarea
                            id="key_result"
                            placeholder="Enter the Key Result in Short"
                            rows="2"
                            v-model="data_local.okr_details[k].key_result"
                          /> -->
                          <!-- </b-form-group>   -->
                          <div class="d-flex align-items-center justify-content-between">
                            <label for="key_result" class="d-block">Key Results</label>
                            <div class="demo-inline-spacing">
                              <a @click="addKeyResult(k,'add')" class="font-weight-bolder text-primary">
                                <feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Key Result</a>
                            </div>
                          </div>
                          <b-row>
                            <b-col cols="12" class="mb-1" v-for="(key_result,key) in data_local.okr_details[k].key_results" :key="key">
                              <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D;border-radius: 10px 10px;padding: 15px;">
                                <b-row>
                                  <b-button @click="removeKeyResult(k,key,'add')" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                                    >
                                    <feather-icon icon="XIcon" size="13" class="text-danger" 
                                    v-b-tooltip.hover.v-danger
                                    title="Remove Key Result"/>
                                  </b-button>
                                  <b-col sm="12" md="612">
                                    <b-form-group
                                        :label="'Key Result #'+(key+1)"
                                        label-for="key-result"
                                      >
                                      <b-form-textarea
                                        id="key-result"
                                        placeholder="Enter the Key Result in Short"
                                        v-model="data_local.okr_details[k].key_results[key].description"
                                        rows="2"
                                        ref="lastAddedInput"
                                      />
                                      </b-form-group>
                                  </b-col>
                                  <b-col sm="12" md="4">
                                    <b-form-group label="Status" label-for="status">
                                      <v-select
                                        :options="statusList"
                                        label="label"
                                        :reduce="(label) => label.code"
                                        :clearable="true"
                                        :searchable="true"
                                        input-id="status"
                                        name="status"
                                        placeholder="Select Status"
                                        v-model="data_local.okr_details[k].key_results[key].status"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="12" md="4">
                                    <b-form-group label="Priority Level" label-for="priority-level">
                                      <v-select
                                        :options="priorityList"
                                        label="label"
                                        :reduce="(label) => label.code"
                                        :clearable="true"
                                        :searchable="true"
                                        input-id="priority-level"
                                        name="priority-level"
                                        placeholder="Select Priority Level"
                                        v-model="data_local.okr_details[k].key_results[key].priority_level"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col md="4">
                                    <b-form-group label="Timeline" label-for="period_range">
                                      <flat-pickr
                                        ref="timeline"
                                        class="form-control"
                                        input-id="timeline"
                                        placeholder="Select Start and End Date"
                                        v-model="data_local.okr_details[k].key_results[key].timeline"
                                        :config="keyResultDateConfig"
                                      />
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                            <!-- Bottom Add Key Results -->
                            <div class="demo-inline-spacing show-keyplus w-100 d-flex align-items-center justify-content-center">
                              <a @click="addKeyResult(k,'add')" class="font-weight-bolder text-white bg-primary">
                                <feather-icon icon="PlusIcon" class="font-weight-bolder"/> <span class="hover-btn">Add Key Result</span></a>
                            </div>
                            <!-- End Bottom Add Key Results -->
                          </b-row>
                          
                        </b-col>
                        <!-- <b-col sm="12" md="4">
                          <b-form-group label="Metric Type" label-for="metric_type">
                            <b-form-radio-group
                              size="lg"
                              id="btn-radios-1"
                              v-model="data_local.okr_details[k].metric_type"
                              button-variant="outline-primary text-primary"
                              :options="metricOption"
                              buttons
                              name="radios-btn-default"
                            />
                             <b-button-group
                              size="lg"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                v-b-tooltip.hover.v-default
                                title="Numeric"
                                @click="data_local.okr_details[k].metric_type = 'Numeric'"
                              >
                                <strong>123</strong>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                v-b-tooltip.hover.v-default
                                title="Percentage"
                                @click="data_local.okr_details[k].metric_type = 'Percentage'"
                              >
                              <strong>%</strong>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="data_local.okr_details[k].metric_type = 'Yes/No'"
                                v-b-tooltip.hover.v-default
                                title="Yes/No"
                              >
                                <feather-icon icon="CheckSquareIcon" size="20" class="text-primary"/>
                              </b-button>
                            </b-button-group> 
                          </b-form-group>
                        </b-col>
                        <b-col sm="12" md="4" v-if="data_local.okr_details[k].metric_type != 'Yes/No'  && data_local.okr_details[k].metric_type != '' ">
                          <b-form-group label="Start" label-for="start">
                          <b-input-group>
                          <b-form-input
                            id="start"
                            type="number"
                            v-model="data_local.okr_details[k].start"
                          />
                          <b-input-group-append is-text v-if="data_local.okr_details[k].metric_type == 'Percentage'">
                            <feather-icon
                              icon="PercentIcon"
                              class="text-primary"
                            />
                          </b-input-group-append>
                        </b-input-group> 
                      </b-form-group> 
                        </b-col>
                        <b-col sm="12" md="4" v-if="data_local.okr_details[k].metric_type != 'Yes/No' && data_local.okr_details[k].metric_type != '' ">
                          <b-form-group label="End" label-for="end">
                          <b-input-group
                            label="end"
                            label-for="End"
                          >
                          <b-form-input
                            id="end"
                            type="number"
                            v-model="data_local.okr_details[k].end"
                          />
                          <b-input-group-append is-text v-if="data_local.okr_details[k].metric_type == 'Percentage'">
                            <feather-icon
                              icon="PercentIcon" class="text-primary"
                            />
                          </b-input-group-append>
                        </b-input-group>  
                        </b-form-group>
                        </b-col> -->
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="border" v-if="data_local.okr_type != 'Organizational OKR' " title="Alignment" sub-title="Adding a parent goal allows your team to be aligned with the whole organization">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Parent OKR Type" label-for="parent_okr_type">
                      <v-select
                        :options="parentOkrTypeList"
                        label="label"
                        :reduce="(label) => label.code"
                        :clearable="true"
                        :searchable="true"
                        input-id="parent_okr_type"
                        name="parent_okr_type"
                        placeholder="Select Parent OKR Type"
                        @input="getOKRbyType(data_local.parent_okr_type)"
                        v-model="data_local.parent_okr_type"
                      />
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Parent OKR" label-for="parent_okr">
                        <v-select
                          :options="parentOptions"
                          label="label"
                          :clearable="true"
                          :searchable="true"
                          input-id="parent_okr"
                          name="parent_okr"
                          v-model="data_local.parent_okr"
                          placeholder="Select Parent OKR"
                          :reduce="(label) => label.code"
                        />
                      </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
          </b-form>
        </b-modal>
			</div>
        </div>
      </div>
			
		</div>
		<!-- OKRs Lists -->
<div class="mx-5 px-5 mobile-design">
  <b-row v-if="myOKRs.length == 0" class="d-flex justify-content-center">
    <b-col md="7">
      <b-card
        title="You don't own any OKRs"
        :img-src="
          require('@/assets/images/pages/coming-soon.svg')
        "
        img-alt="Image"
        img-top
        tag="article"
        class="mb-2 mt-2"
        sub-title="There is no OKRs linked with your account"
        
      >
        <b-card-text>
          You can create Organization, Team and Individual OKRs. Team and Individual OKRs can be linked with Organisation OKR to be listed in OKR Tree.
        </b-card-text>
        <div class="d-flex justify-content-center">
          <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" v-b-modal.new-goal-form>
            <feather-icon icon="PlusIcon" /> New OKR
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="p-1 justify-content-end">
    <b-pagination v-if="myOKRs.length"
      v-model="currentPage"
      :total-rows="total"
      :per-page="per_page"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </b-row>
		<b-card class="okrs-list mb-1" v-for="okr in myOKRs" :key="okr.hashid">
			<b-row class="mb-1">
				<b-col  v-b-modal.goal-details @click="okrOpened(okr)" class="d-flex align-items-center justify-content-between" sm="12" md="5">
	<div class="d-flex align-items-center">
          <b-avatar
              size="36"
              :src="getOKRIcon(okr)"
              style="background:transparent;"
              rounded="0"
            />
          <h4 class="font-weight-bolder mb-0 ml-1">
            {{okr.title}}
          </h4>
	</div>
<b-dropdown class="blog-dropdown d-md-none" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
						<template v-slot:button-content class="px-1">
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
            </template>
						<b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="okrOpened(okr)">
							<feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Edit</span>
            </b-dropdown-item>
						<b-dropdown-item
                  v-if="okr.hashid"
                      v-clipboard:copy="shareUrl(okr.hashid)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0" 
            @click="confirmDeleteRecord(okr)"
            v-if="
              $can('Zircly Admin') ||
              $can('okr_delete')
            ">
							<!-- @click="editRecord(data)" -->
							<feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-badge pill variant="light" class="border text-dark goal-tag">
            <div class="d-flex align-items-center">
              <b-img-lazy fluid :src="require('@/assets/images/icons/individual-goal.png')" alt="goal-icon" width="24px" height="24px"/><span class="px-1 medium">Self Development</span>
            </div>
          </b-badge>
          <b-badge pill variant="light" class="border text-dark goal-tag">
            <div class="d-flex align-items-center">
						  <b-img-lazy fluid :src="require('@/assets/images/icons/team-goal.png')" alt="goal-icon" width="24px" height="24px"/><span class="px-1 medium">Team Contribution</span>
            </div>
          </b-badge> -->
					          
				</b-col>
				<b-col class="text-right mobile-left justify-content-md-end" sm="12" md="7">
					<b-badge pill variant="light" class="border text-dark goal-tag mr-1">
                <b-img-lazy fluid :src="require('@/assets/images/icons/targeting-indi.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">{{okr.okr_type}}</span>
              </b-badge>
					<b-badge variant="light-primary" class="mr-1" v-if="userDataLocal.hashid == okr.created_by.hashid && okr.user.hashid != okr.created_by.hashid">Created by you</b-badge>
					<b-badge variant="primary" v-if="okr.okr_state == 'Completed'">{{okr.okr_state}}</b-badge>
					<b-badge variant="secondary" v-if="okr.okr_state == 'Not started yet'">{{okr.okr_state}}</b-badge>
					<b-badge variant="warning" v-if="okr.okr_state == 'In progress'">{{okr.okr_state}}</b-badge>
					<!-- <feather-icon icon="UsersIcon" size="20" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-default title="Visible to everyone"/> -->
					<b-dropdown class="blog-dropdown d-none d-md-inline" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
						<template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
            </template>
						<b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="okrOpened(okr)">
							<feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Edit</span>
            </b-dropdown-item>
						<b-dropdown-item
                  v-if="okr.hashid"
                      v-clipboard:copy="shareUrl(okr.hashid)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0" 
            @click="confirmDeleteRecord(okr)"
            v-if="
              $can('Zircly Admin') ||
              $can('okr_delete')
            ">
							<!-- @click="editRecord(data)" -->
							<feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
				</b-col>
			</b-row>
    <hr/>
			<h5 class="goal-name font-weight-bolder text-capitalize mt-50" v-b-modal.goal-details @click="okrOpened(okr)" >Owned By : <strong>{{okr.user.name}}</strong></h5>
			<!-- <div class="goal-footer d-flex" v-b-modal.goal-details @click="selectedOKR = okr">
				<span class="action">{{okr.description}}</span>
			</div> -->
		</b-card>
</div>
		<b-modal ref="goal_details" id="goal-details" hide-footer size="lg">
      <template #modal-header="{ close }" class="d-flex align-items-center">
        <b-row class="w-100">
          <b-col class="d-flex align-items-center">
            <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
            <span class="font-weight-bolder text-uppercase" style="margin-left: 5px;margin-top: 5px;">OKR View</span>
          </b-col>
          <b-col class="text-right">
            <b-badge pill variant="light" class="border border-primary text-dark goal-tag ml-2">
              <b-img-lazy fluid :src="require('@/assets/images/icons/tree-view.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">{{selectedOKR.okr_type}}</span>
            </b-badge>
          </b-col>
        </b-row>
<b-button size="sm" variant="outline-transparent" class="p-0" @click="close()">
        <feather-icon icon="XIcon" size="24" class="text-body align-middle ml-2"/>
      </b-button>
      </template>
      <b-card variant="trasaprent" class="border mt-1">
			<b-row class="mb-2">
				<b-col sm="11">
					<h3 class="goal-name font-weight-bolder">{{selectedOKR.title}}</h3>
          <!-- <p class="mb-0">{{selectedOKR.description}}</p> -->
				</b-col>
				<b-col class="text-right" sm="1">
					<!-- <feather-icon icon="UsersIcon" size="16" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-info title="Your direct manager and select managers report to will see the goal"/> -->
					<b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
						<template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
            </template>
						<b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr>
							<feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
							<span class="">Edit</span>
            </b-dropdown-item>
						<b-dropdown-item
                      @click="copyURL(selectedOKR.hashid)"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0"  
            @click="confirmDeleteRecord(selectedOKR)"
            v-if="
              $can('Zircly Admin') ||
              $can('okr_delete')
            ">
							<!-- @click="editRecord(data)" -->
							<feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
				</b-col>
			</b-row>
      <b-row>
        <b-col class="goal-owner d-flex align-items-center">
          <div class="owner-info d-flex align-items-center pr-2 border-right">
            <b-avatar
              size="lg"
              :src="getOKRIcon(selectedOKR)"
              style="background:transparent;"
              rounded="0"
            />
            <div class="pl-1">
            <h6 class="font-weight-bolder">OKR owner</h6>
            <span>{{selectedOKR.user.name}}</span>
            </div>
          </div>
          <div class="date-info d-flex align-items-center">
            <div class="date-finish ml-2  pr-2 border-right">
              <h6 class="font-weight-bolder">Start Date</h6>
              <span>{{selectedOKR.start_date|formatDate}}</span>
            </div>
            <div class="date-finish ml-2">
              <h6 class="font-weight-bolder">End Date</h6>
              <span>{{selectedOKR.end_date|formatDate}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr>
			<div class="goal-progress">
        <b-row justify-content-between>
          <b-col>
          <h4 class="font-weight-bolder my-1 font-weight-bolder">OKR Report <span><b-badge pill variant="info" class="mx-1 small">{{selectedOKR.okr_state}}</b-badge></span></h4>
          </b-col>
          <!-- <b-col class="text-right">
          <b-button variant="outline-primary">Progress View</b-button>
          </b-col> -->
        </b-row>
        <b-progress
          :value="(selectedOKR.progress > 0)?selectedOKR.progress : 0"
          max="100"
          class="progress-bar-primary"
          variant="primary"
          animated
        >
        <b-progress-bar :value="selectedOKR.progress">
          <strong>{{ selectedOKR.progress}}%</strong>
        </b-progress-bar>
        </b-progress>
    </div>
    <h5 class="font-weight-bolder mt-2 font-weight-bolder">Objectives and Key Results</h5>
    <b-tabs class="okr-objdetail">
      <!-- top position -->
      <b-tab :title='"Objectives("+getObjectives.length+")"' active>
        <b-tabs v-for="(objective,k) in getObjectives" :key="k"
          vertical
          nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
          <b-tab
            active
            :title='"Objective "+(k+1)'
          >
            <b-card-text >
              <b-row>
              <b-col cols="12" class="object-type">
                <b-row>
                  <b-col sm="6" md="3" lg="2">
                    <b-badge
                      :variant="`${getStatusClass(getObjectiveStatus(objective))}`"
                      class="badge"
                    >
                      {{getStatusLabel(getObjectiveStatus(objective))}}
                    </b-badge>
                  </b-col>
                  <b-col sm="12" md="6" lg="8">
                  <strong :class="`text-${getStatusClass(getObjectiveStatus(objective))}`">
                    {{objective.objective}}
                  </strong>
                  <b-badge
                      :variant="`${getStatusClass(getObjectiveStatus(objective))}`"
                      class="badge"
                    >
                      {{getObjectiveRatio(objective)}}
                    </b-badge>
                  </b-col>
                  <b-col sm="4" md="3" lg="2" class="text-md-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      v-if="
                      ($can('Zircly Admin') || isCreator(selectedOKR)) &&
                      $can('okr_edit')
                    "
                    @click="selectedObjective = objective"
                    v-b-modal.update-goal
                    class="edit-btn px-2 py-75"
                    >
                      Edit
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" style="margin-top:5px;">
                <b-row>
                  <b-col cols="12">
                    <b-badge
                      variant="success"
                      class="badge"
                    >
                    Key Results
                    </b-badge>
                  </b-col>
                  <b-col cols="12">
                    <!-- <strong class="text-success">
                      {{selectedOKR.okr_details[k].key_results[key].description}}
                    </strong>  -->
                    <b-row>
                      <b-col cols="12" class="mb-1" v-for="(key_result,key) in objective.key_results" :key="key">
                        <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D; border-radius: 10px 10px;padding: 15px;">
                          <b-row>
                            <b-col sm="12" md="12">
                              <b-form-group
                                  :label="'Key Result #'+(key+1)"
                                  label-for="key-result"
                                >
                                <strong class="text-success">
                                  {{key_result.description}}
                                </strong> 
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Status" label-for="status">
                                <b-badge
                                  :variant="`${getStatusClass(key_result.status)}`"
                                  class="badge"
                                >
                                  {{getStatusLabel(key_result.status)}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Priority Level" label-for="priority-level">
                                <b-badge
                                  :variant="`${getPriorityClass(key_result.priority_level)}`"
                                  class="badge"
                                >
                                  {{getPriorityLabel(key_result.priority_level)}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col md="4">
                              <b-form-group label="Timeline" label-for="period_range">
                                <b-badge
                                  variant="success"
                                  class="badge"
                                >
                                  {{key_result.timeline|formatDateRange}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="12" v-if="key_result.notes">
                              <b-form-group
                                  :label="'Key Result Notes #'+(key+1)"
                                  label-for="key-result-notes"
                                >
                                <strong class="text-success">
                                  {{key_result.notes}}
                                </strong> 
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> 
              </b-col>
              
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>


      <!-- left position -->
      <b-tab
        :title='"Experiments("+getExperiments.length+")"'
      >
        <b-tabs v-for="(experiment,k) in getExperiments" :key="k"
          vertical
          nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
          <b-tab
            :title='"Experiment "+(k+1)'
          >
            <b-card-text >
              <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col sm="6" md="3" lg="2">
                    <b-badge
                      :variant="`${getStatusClass(getObjectiveStatus(experiment))}`"
                      class="badge"
                    >
                      {{getStatusLabel(getObjectiveStatus(experiment))}}
                    </b-badge>
                  </b-col>
                  <b-col sm="12" md="6" lg="8">
                    <strong :class="`text-${getStatusClass(getObjectiveStatus(experiment))}`">
                      {{experiment.objective}}
                    </strong>
                    <b-badge
                      :variant="`${getStatusClass(getObjectiveStatus(experiment))}`"
                      class="badge"
                    >
                      {{getObjectiveRatio(experiment)}}
                    </b-badge>
                  </b-col>
                  <b-col sm="4" md="3" lg="2" class="text-md-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      v-if="
                      ($can('Zircly Admin') || isCreator(selectedOKR)) &&
                      $can('okr_edit')
                    "
                    @click="selectedObjective = experiment"
                    v-b-modal.update-goal
		    class="edit-btn px-2 py-75"
                    >
                      Edit
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" style="margin-top:5px;">
                <b-row>
                  <b-col cols="12">
                    <b-badge
                      variant="success"
                      class="badge"
                    >
                    Key Results
                    </b-badge>
                  </b-col>
                  <b-col cols="12">
                    <!-- <strong class="text-success">
                      {{objective.key_result}}
                    </strong>  -->
                    <b-row>
                      <b-col cols="12" class="mb-1" v-for="(key_result,key) in experiment.key_results" :key="key">
                        <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D; border-radius: 10px 10px;padding: 15px;">
                          <b-row>
                            <b-col sm="12" md="12">
                              <b-form-group
                                  :label="'Key Result #'+(key+1)"
                                  label-for="key-result"
                                >
                                <strong class="text-success">
                                  {{key_result.description}}
                                </strong> 
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Status" label-for="status">
                                <b-badge
                                  :variant="`${getStatusClass(key_result.status)}`"
                                  class="badge"
                                >
                                  {{getStatusLabel(key_result.status)}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Priority Level" label-for="priority-level">
                                <b-badge
                                  :variant="`${getPriorityClass(key_result.priority_level)}`"
                                  class="badge"
                                >
                                  {{getPriorityLabel(key_result.priority_level)}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col md="4">
                              <b-form-group label="Timeline" label-for="period_range">
                                <b-badge
                                  variant="success"
                                  class="badge"
                                >
                                  {{key_result.timeline}}
                                </b-badge>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="12" v-if="key_result.notes">
                              <b-form-group
                                  :label="'Key Result Notes #'+(key+1)"
                                  label-for="key-result-notes"
                                >
                                <strong class="text-success">
                                  {{key_result.notes}}
                                </strong> 
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> 
              </b-col>
              
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
    </b-card>
    <!-- <h4 class="font-weight-bolder">Activity</h4>
    <b-card class="border">
      <b-list-group>
        <b-list-group-item>
          <div class="goal-owner d-flex align-items-center pr-2 border-right">
            <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="goal-img" width="42px" height="42px" fluid/>
            <div class="pl-1">
            <h6><b>You</b> updated the goal status to Delayed.</h6>
            <span>2 days ago</span>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="selectedOKR.created_by">
          <div class="goal-owner d-flex align-items-center pr-2 border-right">
            <b-img-lazy fluid :src="selectedOKR.created_by.avatar" alt="goal-img" width="42px" height="42px" fluid/>
            <div class="pl-1">
            <h6><b>{{selectedOKR.created_by.name}}</b> created the OKR.</h6>
            <span>{{selectedOKR.created_at}}</span>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card> -->
    <!-- Sub OKRs-->
    <b-row class="mb-1">
      <b-col><h4 class="font-weight-bolder">Sub-OKRs</h4></b-col>
      <!-- <b-col class="text-right">
        <a v-b-toggle href="#expand-notes" @click.prevent class="font-weight-bolder"><feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Sub-okrs</a>
      </b-col> -->
    </b-row>
    <b-card class="border">
      <b-list-group>
        <b-list-group-item v-if="selectedOKR.sub_okrs.length && selectedOKR.hashid != sub_okr.hashid" v-for="sub_okr in selectedOKR.sub_okrs" :key="sub_okr.hashid">
          <div>
            <b-row class="goal-owner d-flex align-items-center">
              <b-col md="5" class="d-flex align-items-center">
                <b-img-lazy fluid :src="getOKRIcon(sub_okr)" alt="goal-img" width="36px" height="36px" fluid/>
                <h4 class="text-capitalize mb-0 ml-1">{{sub_okr.title}}</h4>
              </b-col>
              <b-col md="7" class="justify-content-end d-flex align-items-center">
                <span>{{sub_okr.user.name}}</span>
                <span class="px-1">{{sub_okr.end_date|formatDate}}</span>
                <div class="goal-detailed-progress d-flex justify-content-between align-items-center">
                  <div class="progress-bar mr-1">
                    <div class="progress-view" role="progressbar" style="width: 20%;"></div>
                  </div>
                  <h5 class="progress-report mb-0">{{sub_okr.progress}}%</h5>
                </div>
                <b-badge pill variant="info" class="ml-1 small">{{sub_okr.okr_state}}</b-badge>
              </b-col>
            </b-row>
          </div>
          
        </b-list-group-item>
        <b-list-group-item v-if="selectedOKR.sub_okrs.length == 0 || (selectedOKR.sub_okrs.length == 1 && selectedOKR.hashid == selectedOKR.sub_okrs[0].hashid)">
         No Sub OKRs....
        </b-list-group-item>
    </b-list-group>
    </b-card>
    <!-- End Sub OKRs-->
    <!-- Comment Section -->
    <h4 class="font-weight-bolder mb-1">Comments</h4>
    <div class="post-card common-cmd">
      <b-card class="shadow-none mb-0">
        <b-list-group>
          <!-- <b-list-group-item>
            <div class="goal-owner d-flex align-items-center pr-2 border-right">
              <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="goal-img" width="42px" height="42px" fluid/>
              <div class="pl-1">
              <h6><b>You</b> updated the goal status to Delayed.</h6>
              <span>2 days ago</span>
              </div>
            </div>
          </b-list-group-item> -->
          <b-list-group-item v-for="comment in VisibleComments" :key="comment.hashid" class="bg-transparent reply-post py-50 border-0 px-0">
            <div class="inner-cmd">
              <div class="d-flex align-items-start">
                <b-img-lazy fluid :src="comment.user.avatar" alt="user-img" width="32px" height="32px" class="mr-75"/>
                <div class="profile-user-info w-100 right-cmt">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="goal-owner d-flex align-items-center">
                      <div>
                        <h6><b>{{comment.user.name}}</b></h6>
                        <small>{{ comment.user.designation }}</small>
                      </div>
                    </div>
                    <div class="d-flex align-items-start mb-1">
                      <b-badge variant="primary" class="mr-1 d-none d-lg-block" v-if="comment.visiblity_type == 'Private' " >Private</b-badge>
                      <b-badge variant="light-primary" class="mr-1 d-none d-lg-block" v-if="comment.visiblity_type == 'Public' " >Public</b-badge>
                      <span class="small mr-50 ">{{comment.created_at}}</span>
                      <b-link class="btn btn-icon text-primary rounded-circle p-0 d-none d-lg-block"><feather-icon icon="CornerUpLeftIcon" size="18" @click="addReply(comment)" /></b-link>
                      <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0" style="margin-top: 2px" no-caret>
                    <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                        </template>
                        <b-dropdown-item class="btn-sm p-0" @click="addReply(comment)" >
                          <feather-icon icon="CornerUpLeftIcon" size="13" class="mr-50 text-primary"/>
                          <span class="">Reply to comment</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0" v-if="comment.user.hashid == userDataLocal.hashid" @click="editReply(comment)">
                          <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
                          <span class="">Edit comment</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0" v-if="selectedOKR.user.hashid == userDataLocal.hashid || comment.user.hashid == userDataLocal.hashid" @click="deleteConfirm(comment,selectedOKR)">
                          <feather-icon icon="XIcon" size="13" class="mr-50 text-danger  "/>
                          <span class="">Delete comment</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0"  v-if="comment.visiblity_type == 'Public' " @click="changeStatus(comment,'Private')">
                          <feather-icon icon="LockIcon" size="13" class="mr-50 text-primary"/>
                          <span class="">Make it Private</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0"  v-if="comment.visiblity_type == 'Private' " @click="changeStatus(comment,'Public')">
                          <feather-icon icon="BookOpenIcon" size="13" class="mr-50 text-primary"/>
                          <span class="" >Make it Public</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                      <p
                        v-html="comment.comment"
                        class="mb-0 mt-1"
                        style="letter-spacing: 1px; font-size: 14px"
                      ></p>
                      <div>
                </div>
                </div>
              </div>
              <div class="border-top w-100 p-75 mt-50">
              </div>
            </div>
            <div class="reply-cmd">
            <Replies
                        v-if="comment.reply_count"
                        :itemId="comment.hashid"
                        :modelId="selectedOKR.hashid"
                        :reply_count="comment.reply_count"
                        :mentionList="employeeListOptions"
                        v-on:refresh="refresh"
                      />
                      <b-collapse  :id="'add-cmd-' + comment.hashid" class="my-50">
            <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center mb-50">
              <div class="border p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round w-100">
                <CommentBox 
                  placeholder="Reply to comment..." className="comment-box"
                  :toolbar="false"
                  :mentionList="employeeListOptions"
                  :id="'reply-comment-field-' + comment.hashid"
                  />
                </div>
                <b-button
                  variant="primary"
                  @click="AddOKRComment(comment, selectedOKR)"
                  pill
                  class="blog-btn-block ml-1 search-post rounded-circle p-75 mb-0"
                  :disabled="commentSubmitClicked"
                >
                  <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
                </b-button>
              </div>
            </b-collapse>
            <b-collapse  :id="'edit-cmd-' + comment.hashid" class="my-50">
              <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center mb-50">
                <div class="border p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round w-100">
                  <CommentBox 
                  placeholder="Reply to comment..." className="w-100 comment-box"
                  :toolbar="false"
                  :mentionList="employeeListOptions"
                  :id="'edit-comment-field-' + comment.hashid"
                  :value.sync="comment.comment"
                  />
                </div>
                  <b-button
                  variant="primary"
                  @click="editComment(comment, selectedOKR.hashid)"
                  pill
                  class="blog-btn-block ml-50 search-post save-btn w-auto mb-0"
                  :disabled="commentSubmitClicked"
                >
                <strong><b>Save</b></strong>
                </b-button>
              </div>
            </b-collapse>
                    </div>
          </b-list-group-item>
        <b-list-group-item v-if="visibleComments.length == 0">
          No Comments to Display.....
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card>
      <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center">
          <div class="border p-751 d-flex flex-row align-items-center justify-content-around commentBox round w-100">
            <CommentBox 
              placeholder="Add a comment..." className="w-100 comment-box "
              :toolbar="false"
              :mentionList="employeeListOptions"
              :id="'add-comment-field-' + selectedOKR.hashid"
            />
          </div>
          <b-button
            variant="primary"
            @click="AddOKRComment(null,selectedOKR)"
            pill
            class="blog-btn-block ml-1 search-post rounded-circle p-75 mb-0"
            :disabled="commentSubmitClicked"
          >
            <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
          </b-button>
        </div>
        </b-card>
    </div>
    <!-- End Comment Section -->
  </b-modal>
		<!-- <b-card class="okrs-list">
			<b-row class="mb-2">
				<b-col>
					<b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
					<b-badge pill variant="light" class="border text-dark goal-tag">
						<b-img-lazy fluid :src="require('@/assets/images/icons/self-view.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">Individual OKR</span>
					</b-badge>
				</b-col>
				<b-col class="text-md-right">
					<b-badge variant="primary">Completed</b-badge>
					<feather-icon icon="UserIcon" size="16" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-info title="Your direct manager and select managers report to will see the goal"/>
					<b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
						<template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
            </template>
						<b-dropdown-item class="btn-sm p-0" v-b-toggle.edit>
							@click="editRecord(data)"
							<feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Edit</span>
            </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0">
							@click="editRecord(data)"
							<feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Archive</span>
            </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)">
							@click="editRecord(data)"
							<feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
				</b-col>
			</b-row>
			<h5 class="goal-name font-weight-bolder">Product Improvement</h5>
			<div class="goal-footer d-flex my-1">
				<span>8 days</span>
				<span>-</span>
				<span class="action">0/1 Objective items completed</span>
			</div>
		</b-card> -->
	</div>
	<!-- End Self OKR Page-->
  <b-modal id="delete-record" hide-footer>
    <div class="modal-inside text-center">
      <h3>Are you sure?</h3>
      <h5 class="my-1">Removing this individual goal will also remove any linked Objective items. This operation is irreversible.</h5>
      <b-form-group>
        <b-button variant="outline-secondary" class="mr-1">Cancel</b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="ml-1">Remove</b-button>
      </b-form-group>
    </div>
  </b-modal>
  <b-modal ref="edit_okr" id="edit-okr" centered size="xl" style="background-color: transparent;" ok-title="Update" @ok.prevent="formUpdated()" @shown="showOkrForm" no-close-on-backdrop>
    <template #modal-header="{ close }">
      <h5 class="mb-0 font-weight-bolder">What do you want to accomplish?</h5>
      <b-button size="sm" variant="outline-transparent" class="p-0" @click="close()">
        <feather-icon icon="XIcon" size="24" class="text-body align-middle ml-2"/>
      </b-button>
    </template>
    <h4 class="text-center pt-1 font-weight-bolder">We all have things to get done. No matter how big or small, it starts with a goal. </h4>
    <h5 class="text-center mb-2">So let’s get started!</h5>

    <b-form autocomplete="off" id="okr-form">
        <b-card class="border">
          <b-card-text class="d-flex align-items-center border-bottom-primary pb-1">
            <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
            <input ref="okrname" v-model="selectedOKR.title" class="font-weight-bold mb-0 ml-1 border-0 w-100 font-weight-bolder" style="font-size:20px;" type="text" placeholder="Enter the OKR title here..." />
            <!-- <h4 class="font-weight-bold mb-0 ml-1">OKR Title</h4> -->
          </b-card-text>
          <b-row>
            <b-col md="6">
              <b-form-group label="OKR Period Range" label-for="period_range">
                <flat-pickr
                  ref="period_range"
                  class="form-control"
                  input-id="period_range"
                  placeholder="Select Start and End Date"
                  v-model="selectedOKR.period_range"
                  :config="dateConfig"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="OKR Type" label-for="okr_type">
                          <v-select
                            :options="okrTypeList"
                            label="label"
                            :reduce="(label) => label.code"
                            :clearable="true"
                            :searchable="true"
                            input-id="okr_type"
                            name="okr_type"
                            placeholder="Select OKR Type"
                            v-model="selectedOKR.okr_type"
                            :value.sync="selectedOKR.okr_type"
                          />
                        </b-form-group>
            </b-col>
            <b-col md="6" 
                  v-if="$can('Zircly Admin') || $can('Manager')">
                    <b-form-group label="OKR Owner" label-for="okr_owner">
                                <v-select
                                  :options="employeeOptions"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="okr_owner"
                                  name="okr_owner"
                                  placeholder="Select OKR Owner"
                                  v-model="selectedOKR.okr_owner"
                                />
                              </b-form-group>
                  </b-col>
          </b-row>
          <b-alert show>
            <b-row class="px-2 py-1">
              <b-col cols="auto">
                <b-img-lazy fluid :src="require('@/assets/images/icons/idea.png')" alt="goal-img" width="30px" height="30px"/>
              </b-col>
              <b-col>
                <p class="text-dark">Capture what you hope to accomplish with your goal. Try to keep it specific and objective-focussed. Struggling with a title? Skip this section for now and return to it later.</p>
              </b-col>
            </b-row>      
          </b-alert>
        </b-card>
        <b-card class="border obj-main">
          <div class="obj-header">
            <b-row>
              <b-col>
                <h4>Define your Objectives and Key Results</h4>
                <h5 class="small">Use this SMART framework to create effective okrs that keep you headed in the right direction.</h5>
                  </b-col>
                  <b-col sm="12" md="3" class="text-md-right">
                    <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                @click="addOnSelected()"
              >
              <feather-icon icon="PlusIcon" 
              size="15"/> Add Objective
              </b-button>
              </b-col>
            </b-row>
            <hr>
          </div>
          <b-row v-if="selectedOKR.okr_details" class="obj-details">
            <b-col md="12" v-for="(action_item,k) in selectedOKR.okr_details" :key="k">
              <div style="border: 2px solid #38B22D;padding: 20px;border-radius: 50px 20px;" class="mt-1 relative">
                <b-button @click="removeFromSelected(k)" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                        >
                        <feather-icon icon="TrashIcon" size="13" class="text-danger" 
                        v-b-tooltip.hover.v-danger
                        title="Remove Objective"/>
                      </b-button>
                <h5 class="obj-title">  Objective {{k+1}} </h5>
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group label="Type" label-for="objective_type">
                      <v-select
                        :options="typeList"
                        label="label"
                        :reduce="(label) => label.code"
                        :clearable="true"
                        :searchable="true"
                        input-id="objective_type"
                        name="objective_type"
                        placeholder="Select OKR Type"
                        v-model="selectedOKR.okr_details[k].type"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col sm="12" md="6">
                    <b-form-group label="Status" label-for="status">
                      <v-select
                        :options="statusList"
                        label="label"
                        :reduce="(label) => label.code"
                        :clearable="true"
                        :searchable="true"
                        input-id="status"
                        name="status"
                        placeholder="Select Status"
                        v-model="selectedOKR.okr_details[k].status"
                      />
                    </b-form-group>
                    </b-col> -->
                  <b-col sm="12" md="12">
                    <b-form-group
                        label="Objective"
                        label-for="objective"
                      >
                      <b-form-textarea
                        id="objective"
                        placeholder="Enter the Objective in Short"
                        v-model="selectedOKR.okr_details[k].objective"
                        rows="2"
                        
                      />
                      </b-form-group>
                  </b-col>
                  
                  <b-col sm="12" md="12">
                    <!-- <b-form-group
                      label="Key Result"
                      label-for="key_result"
                      icon="CheckSquareIcon"
                    > -->

                    <!-- <b-form-textarea
                      id="key_result"
                      placeholder="Enter the Key Result in Short"
                      rows="2"
                      v-model="selectedOKR.okr_details[k].key_result"
                    /> -->
                    <!-- </b-form-group>   -->
                    <div class="d-flex align-items-center justify-content-between">
                      <label for="key_result" class="d-block">Key Results</label>
                      <div class="demo-inline-spacing">
                        <a @click="addKeyResult(k,'update')" class="font-weight-bolder text-primary">
                          <feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Key Result</a>
                      </div>
                    </div>
                    <b-row>
                      <b-col cols="12" class="mb-1" v-for="(key_result,key) in selectedOKR.okr_details[k].key_results" :key="key">
                        <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D;border-radius: 10px 10px;padding: 15px;">
                          <b-row>
                            <b-button @click="removeKeyResult(k,key,'update')" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                              >
                              <feather-icon icon="XIcon" size="13" class="text-danger" 
                              v-b-tooltip.hover.v-danger
                              title="Remove Key Result"/>
                            </b-button>
                            <b-col sm="12" md="12">
                              <b-form-group
                                  :label="'Key Result #'+(key+1)"
                                  label-for="key-result"
                                >
                                <b-form-textarea
                                  id="key-result"
                                  placeholder="Enter the Key Result in Short"
                                  v-model="selectedOKR.okr_details[k].key_results[key].description"
                                  rows="2"
                                  ref="lastAddedInput"
                                />
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Status" label-for="status">
                                <v-select
                                  :options="statusList"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="status"
                                  name="status"
                                  placeholder="Select Status"
                                  v-model="selectedOKR.okr_details[k].key_results[key].status"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Priority Level" label-for="priority-level">
                                <v-select
                                  :options="priorityList"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="priority-level"
                                  name="priority-level"
                                  placeholder="Select Priority Level"
                                  v-model="selectedOKR.okr_details[k].key_results[key].priority_level"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="4">
                              <b-form-group label="Timeline" label-for="period_range">
                                <flat-pickr
                                  ref="timeline"
                                  class="form-control"
                                  input-id="timeline"
                                  placeholder="Select Start and End Date"
                                  v-model="selectedOKR.okr_details[k].key_results[key].timeline"
                                  :config="keyResultDateConfig"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <!-- Bottom Add Key Results -->
                      <div class="demo-inline-spacing show-keyplus w-100 d-flex align-items-center justify-content-center">
                              <a @click="addKeyResult(k,'update')" class="font-weight-bolder text-white bg-primary">
                                <feather-icon icon="PlusIcon" class="font-weight-bolder"/> <span class="hover-btn">Add Key Result</span></a>
                            </div>
                            <!-- End Bottom Add Key Results -->
                    </b-row>
                    
                  </b-col>
                  <!-- <b-col sm="12" md="4">
                    <b-form-group label="Metric Type" label-for="metric_type">
                      <b-form-radio-group
                        size="lg"
                        id="btn-radios-1"
                        v-model="selectedOKR.okr_details[k].metric_type"
                        button-variant="outline-primary text-primary"
                        :options="metricOption"
                        buttons
                        name="radios-btn-default"
                      />
                      <b-button-group
                        size="lg"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          v-b-tooltip.hover.v-default
                          title="Numeric"
                          @click="selectedOKR.okr_details[k].metric_type = 'Numeric'"
                        >
                          <strong>123</strong>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          v-b-tooltip.hover.v-default
                          title="Percentage"
                          @click="selectedOKR.okr_details[k].metric_type = 'Percentage'"
                        >
                        <strong>%</strong>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectedOKR.okr_details[k].metric_type = 'Yes/No'"
                          v-b-tooltip.hover.v-default
                          title="Yes/No"
                        >
                          <feather-icon icon="CheckSquareIcon" size="20" class="text-primary"/>
                        </b-button>
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4" v-if="selectedOKR.okr_details[k].metric_type != 'Yes/No'  && selectedOKR.okr_details[k].metric_type != '' ">
                    <b-form-group label="Start" label-for="start">
                    <b-input-group>
                    <b-form-input
                      id="start"
                      type="number"
                      v-model="selectedOKR.okr_details[k].start"
                    />
                    <b-input-group-append is-text v-if="selectedOKR.okr_details[k].metric_type == 'Percentage'">
                      <feather-icon
                        icon="PercentIcon"
                        class="text-primary"
                      />
                    </b-input-group-append>
                  </b-input-group> 
                </b-form-group> 
                  </b-col>
                  <b-col sm="12" md="4" v-if="selectedOKR.okr_details[k].metric_type != 'Yes/No' && selectedOKR.okr_details[k].metric_type != '' ">
                    <b-form-group label="End" label-for="end">
                    <b-input-group
                      label="end"
                      label-for="End"
                    >
                    <b-form-input
                      id="end"
                      type="number"
                      v-model="selectedOKR.okr_details[k].end"
                    />
                    <b-input-group-append is-text v-if="selectedOKR.okr_details[k].metric_type == 'Percentage'">
                      <feather-icon
                        icon="PercentIcon" class="text-primary"
                      />
                    </b-input-group-append>
                  </b-input-group>  
                  </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="border" v-if="selectedOKR.okr_type != 'Organizational OKR' " title="Alignment" sub-title="Adding a parent goal allows your team to be aligned with the whole organization">
          <b-row>
            <b-col md="6">
              <b-form-group label="Parent OKR Type" label-for="parent_okr_type">
                <v-select
                  :options="parentOkrTypeList"
                  label="label"
                  :reduce="(label) => label.code"
                  :clearable="true"
                  :searchable="true"
                  input-id="parent_okr_type"
                  name="parent_okr_type"
                  placeholder="Select Parent OKR Type"
                  v-model="selectedOKR.parent_okr_type"
                  :value.sync="selectedOKR.parent_okr_type"
                  @input="parentTypeChanged()"
                />
                </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Parent OKR" label-for="parent_okr">
                  <v-select
                    :options="parentOptions"
                    label="label"
                    :clearable="true"
                    :searchable="true"
                    input-id="parent_okr"
                    name="parent_okr"
                    v-model="selectedOKR.parent_okr_hashid"
                    placeholder="Select Parent OKR"
                    :reduce="(label) => label.code"
                    :value.sync="selectedOKR.parent_okr_hashid"
                  />
                </b-form-group>
            </b-col>
          </b-row>
        </b-card>
    </b-form>
  </b-modal>
  <!-- Update OKR Progress Popup -->
  <b-modal id="update-goal" ref="update_goal" size="lg" centered variant="transparent" @ok.prevent="formUpdated()" ok-title="Update">
    <h4 class="text-center font-weight-bolder"> <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px" style="margin-bottom: 5px"/> Update Objective Progress</h4>
    <b-row>
      <b-col sm="12">
        <h5 class="my-1 font-weight-bolder">Type</h5>
        <b-form-group class="goal-type">
          <div class="d-flex align-items-center">
          <b-form-radio v-model="selectedObjective.type" name="objective" value="Objective">Objective</b-form-radio>
          <b-form-radio v-model="selectedObjective.type" name="experiment and learn" value="Experiment">Experiment and Learn</b-form-radio>
          </div>
        </b-form-group>
      </b-col>
      <!-- <b-col sm="12" class="radio-wrap">
        <div class="form-group">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="my-1 font-weight-bolder">Status</h5>
            </div>
          <div class="btn-group form-control border-0 p-0" role="group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status1" value="0" v-model="selectedObjective.status" :checked="selectedObjective.status == 0">
              <label class="form-check-label border-secondary text-secondary rounded" for="select-status1">Not Started Yet</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status2" value="1" v-model="selectedObjective.status" :checked="selectedObjective.status == 1">
              <label class="form-check-label border-warning text-warning rounded" for="select-status2">In Progress</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status3" value="2" v-model="selectedObjective.status" :checked="selectedObjective.status == 2">
              <label class="form-check-label border-primary text-primary rounded" for="select-status3">Completed</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status4" value="3" v-model="selectedObjective.status" :checked="selectedObjective.status == 3">
              <label class="form-check-label border-info text-info rounded" for="select-status4">On Hold</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status5" value="4" v-model="selectedObjective.status" :checked="selectedObjective.status == 4">
              <label class="form-check-label border-danger text-danger rounded" for="select-status5">Cancelled</label>
            </div>
          </div>
        </div>
      </b-col> -->
    </b-row>
    <b-collapse id="expand-notes">
      <b-row>
        <b-col>
          <div class="form-group">
            <h5 class="font-weight-bolder">Notes</h5>
            <textarea  v-model="selectedObjective.notes" class="form-control" id="requestDescription" rows="2" cols="50" maxlength="250" placeholder="Type your issue description. . . "></textarea>
          </div>
        </b-col>
      </b-row>
    </b-collapse>
    <hr>
    <!-- <div class="goal-progress">
    <h5 class="font-weight-bolder my-1 font-weight-bolder">Progress</h5>
    <div class="goal-detailed-progress d-flex justify-content-between align-items-center">
        <div class="progress-bar mr-1">
          <div class="progress-view" role="progressbar" style="width: 20%;"></div>
        </div>
        <h5 class="progress-report mb-0">0%</h5>
      </div>
    </div> -->
    <h5 class="my-1 font-weight-bolder">Key Results</h5>
      <b-card class="border sub-editer">
        <div class="demo-inline-spacing row">
          <a @click="addSelectedKeyResult()" class="font-weight-bolder text-primary">
          <feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Key Result</a>
        </div>
        <b-row>
          <b-col cols="12" class="mb-1" v-for="(key_result,key) in selectedObjective.key_results" :key="key">
            <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D;border-radius: 10px 10px;padding: 15px;">
              <b-row>
                <b-button @click="removeSelectedKeyResult(key)" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                  >
                  <feather-icon icon="XIcon" size="13" class="text-danger" 
                  v-b-tooltip.hover.v-danger
                  title="Remove Key Result"/>
                </b-button>
                <b-col sm="12">
                  <b-form-group
                      :label="'Key Result #'+(key+1)"
                      label-for="key-result"
                    >
                    <b-form-textarea
                      id="key-result"
                      placeholder="Enter the Key Result in Short"
                      v-model="selectedObjective.key_results[key].description"
                      rows="2"
                      ref="lastAddedInput"
                    />
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-group label="Status" label-for="status">
                    <v-select
                      :options="statusList"
                      label="label"
                      :reduce="(label) => label.code"
                      :clearable="true"
                      :searchable="true"
                      input-id="status"
                      name="status"
                      placeholder="Select Status"
                      v-model="selectedObjective.key_results[key].status"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-group label="Priority Level" label-for="priority-level">
                    <v-select
                      :options="priorityList"
                      label="label"
                      :reduce="(label) => label.code"
                      :clearable="true"
                      :searchable="true"
                      input-id="priority-level"
                      name="priority-level"
                      placeholder="Select Priority Level"
                      v-model="selectedObjective.key_results[key].priority_level"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="12">
                  <b-form-group label="Timeline" label-for="period_range">
                    <flat-pickr
                      ref="timeline"
                      class="form-control"
                      input-id="timeline"
                      placeholder="Select Start and End Date"
                      v-model="selectedObjective.key_results[key].timeline"
                      :config="keyResultDateConfig"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <a v-b-toggle :href="'#expand-notes-'+key" @click.prevent class="font-weight-bolder"><feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Note</a>
          
              <b-collapse :id="'expand-notes-'+key">
                <hr>
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <h5 class="font-weight-bolder">Notes</h5>
                      <textarea  v-model="selectedObjective.key_results[key].notes" class="form-control" id="requestDescription" rows="2" cols="50" maxlength="250" placeholder="Type your issue description. . . "></textarea>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
        <div class="empty-data d-flex align-items-center justify-content-center">
          <b-img-lazy fluid :src="require('@/assets/images/icons/key.png')" alt="key-note" size="5rem"/>
          <div class="empty-title ml-1">
            <h6 class="font-weight-bolder">Key results help you measure your progress toward the goal</h6>
            <p class="small">Edit your goal to create key results.</p>
          </div>
        </div>
      </b-card>  
  </b-modal>
  <!-- End Update OKR Progress Popup -->
</div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import ProfilePost from "@/views/feed/ProfilePost.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BModal,
  BTable,
  BButton,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BProgress,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import CommentBox from "@/components/CommentBox.vue";
import okrStoreModule from "../okrStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import VueCookies from "vue-cookies";
import { D } from "caniuse-lite/data/agents";
import { VueGoodTable } from 'vue-good-table';
import Replies from "@/views/okrs/Replies.vue";
import $ from "jquery";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    ToastificationContent,
    StarRating,
    flatPickr,
    BModal,
    BTable,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    CommentBox,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BCollapse,
    Replies,
    BProgress,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-collapse":BCollapse,
    Ripple,
  },
  data() {
    return {
      lastPage: 10,
      per_page: 10,
      total: 0,
      nextPage: 1,
      currentPage: 1,
      meta:[],
      userDataLocal: this.$cookies.get("userData"),
      newComment:'',
      replyComment:'',
      employeeListOptions:[],
      visibleComments:[],
      selectedObjective:{
          'type':'',
          'objective':'',
          'objective_description':'',
          'key_result':'',
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        },
      lastAddedInput: null,
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectOkr: "",
      data_local: {
        title:'',
        description:'',
        period_range:'',
        okr_type:'Individual OKR',
        okr_owner:'',
        parent_okr_type:'',
        parent_okr:'',
        okr_details:[{
          'type':'Objective',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':0,
            'priority_level':0,
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        }],
        get_customfields_data: [],
      },
      dateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: 'range',
      },

      keyResultDateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: 'range',
      },
      okrTypeList:[
        {
          label: "Team OKR",
          code: "Team OKR",
        },
        {
          label: "Individual OKR",
          code: "Individual OKR",
        }
      ],
      parentOkrTypeList:[
        {
          label: "Organizational OKR",
          code: "Organizational OKR",
        },
        {
          label: "Team OKR",
          code: "Team OKR",
        },
        {
          label: "Individual OKR",
          code: "Individual OKR",
        }
      ],
      typeList:[
        {
          label: "Objective",
          code: "Objective",
        },
        {
          label: "Experiment and Learn",
          code: "Experiment",
        }
      ],
      priorityList:[
        {
          label: "Low",
          code: 0,
        },
        {
          label: "Medium",
          code: 1,
        },
        {
          label: "High",
          code: 2,
        },
      ],
      statusList:[
        {
          label: "Not Started Yet",
          code: 0,
        },
        {
          label: "In Progress",
          code: 1,
        },
        {
          label: "Completed",
          code: 2,
        },
        {
          label: "On Hold",
          code: 3,
        },
        {
          label: "Cancelled",
          code: 4,
        },
      ],
      customFields: [],
      modes: ['multi', 'single', 'range'],
      fields: ['selected', 'type', { key: 'objective', label: 'Title' },{ key: 'status', label: 'Status' }],
      items: [],
      status: [{
        0:'Not Started Yet' ,1: 'In Progress', 2: 'Completed', 3: 'On Hold', 4: 'Cancelled',
      },
      {
        0:'dark' , 1: 'warning', 2: 'success', 3: 'info', 4: 'danger',
      }],
      selectMode: 'multi',
      selected: [],
      selectedOKR: {
        title:'',
        description:'',
        period_range:'',
        okr_type:'',
        okr_owner:'',
        parent_okr_type:'',
        parent_okr:'',
        sub_okrs:[],
        okr_details:[{
          'type':'',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':'',
            'priority_level':'',
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        }],
        user:{
          avatar:"",
        },
        get_customfields_data: [],
      },
      objectives:[],
      experiments:[],
      myOKRs:[],
      parentOptions:[],
      metricOption: [
        { text: '123', value: 'Numeric' },
        { text: '%', value: 'Percentage' },
      ],
      employeeOptions:[],
      okr_settings:[],
      commentSubmitClicked:false,
    }

  },


  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-okrs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, okrStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.getMyOkr();
    this.getOKRbyType('all')
    //this.getUserPreviousOKRPendingObjectives();
    this.$store
      .dispatch("app/fetchEmployeeList")
      .then((res) => {
        this.employeeOptions = res.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    this.fetchEmployeeList();
    
  },mounted(){
    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > 3000) {
            $('.tweets').addClass('active-sticky');
        }else{
            $('.tweets').removeClass('active-sticky');
        }
    });
  },
  watch: {
    currentPage(val) {
      this.getMyOkr();
    }, 
    selectedOKR(val){
      
    }   
  },
  methods:{
    showOkrForm() {
      this.$refs.okrname.focus()
    },
    okrOpened(okr){
      this.selectedOKR = okr;
      this.getOKRbyType(okr.parent_okr_type);
    },
    resetVariables(){
      this.selectedOKR = {};
      this.selectedObjective = {};
    },
    addReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.id;
      $('#add-cmd-'+comment.hashid).show();
      $('#edit-cmd-'+comment.hashid).hide();
    },
    editReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      $('#add-cmd').hide();
      $('#edit-cmd-'+comment.hashid).show();
      document.getElementById('edit-comment-field-' + comment.hashid).focus();
    },
    deleteConfirm(comment,okr){
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this comment`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteComment(comment.hashid,okr.hashid);
        }
      });
    },

    deleteComment(commentId, okrId) {
      this.$http
        .delete(`/okr/${okrId}/comments/${commentId}`)
        .then((res) => {
          this.visibleComments = [];
          this.selectedOKR = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Comment deleted successfully',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
        });
    },
    
    changeStatus(comment,status){
      let params = {};
      params = {
        comment: comment.comment,
        visiblity_type:status
      };

      this.$http
        .patch(`okr/comment/${comment.hashid}`, params)
        .then((res) => {
          this.visibleComments = [];
          for (let index = 0; index < this.selectedOKR.comments.length; index++) {
            if(this.selectedOKR.comments[index].hashid == comment.hashid){
              this.selectedOKR.comments[index].visiblity_type = status;
            }            
          }
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment Update Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Update Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    editComment(comment, okr_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(
        `edit-comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Reply cannot be empty",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reply cannot be empty',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

      this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`okr/comment/${comment.hashid}`, params)
        .then((res) => {
          // this.editComments.pop();
          this.visibleComments = [];
          this.selectedOKR =  res.data.data;
          this.comment_id = null;
          document.getElementById(`edit-comment-field-${comment.hashid}`).value = "";
          $(".ql-editor").html("");
          $('#add-cmd').hide();
          $('#edit-cmd-'+comment.hashid).hide();
          this.refresh();
          this.commentSubmitClicked=false;

        })
        .catch((error) => {
          this.commentSubmitClicked=false;
          console.log(error);
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    refresh(){
      this.$router.go();
    },
    AddOKRComment(cmd,okr) {
      this.commentSubmitClicked=true;
      if(cmd){
       var comment = document.getElementById(`reply-comment-field-${cmd.hashid}`).value;
      }else{
      var comment = document.getElementById(`add-comment-field-${okr.hashid}`).value;
      }
      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/okr/${okr.hashid}/comments`, params)
        .then((res) => {
          this.visibleComments = [];
          this.selectedOKR.comments = res.data.data.comments;
          this.comment_id = null;
          if(cmd){
            document.getElementById(`reply-comment-field-${cmd.hashid}`).value = '';
          }
          else{
            document.getElementById(`add-comment-field-${okr.hashid}`).value = '';
          }
          $(".ql-editor").html("");
          $('#add-cmd').hide();
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          this.commentSubmitClicked=false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "OKR Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    isCreator(okr) {
      if (okr.user.hashid == this.userDataLocal.hashid) {
        return true;
      }
      return false;
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-okrs/removeOkr", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "OKR Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'OKR Deleted Successfully.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.getMyOkr();
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          this.getMyOkr();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "OKR Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `OKR Deletion failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    getStatusClass(status){
    if (status == 0) return "dark";
    if (status == 1) return "warning";
    if (status == 2) return "success";
    if (status == 3) return "info";
    if (status == 4) return "danger";
    return "primary";
    },
    getStatusLabel(status){
      var label = "Not Started Yet";
      for(var key in this.statusList){
        if(status == this.statusList[key].code){
          label = this.statusList[key].label
        }
      }
      return label
    },
    getOKRbyType(type,mode ='create'){
      
      var startDate = this.data_local.period_range.split(' to ')[0];
      this.data_local.parent_okr = '';
      if(mode == 'update'){
        var startDate = this.selectedOKR.period_range.split(' to ')[0];
      }
      axios
        .post("OKRs/by_type",{ okr_type : type , selectedDate:startDate})
        .then((response) => {
          if (response.data.success) {
            this.parentOptions = response.data.data
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch OKRs for parent",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch OKRs for parent',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
               
          }
        });
    },
    getMyOkr(){
      axios
        .get("myOKRs?page="+this.currentPage)
        .then((response) => {
          if (response.data.success) {

            this.myOKRs = response.data.data
            if(response.data.meta){
              this.nextPage = response.data.meta.current_page + 1;
              this.lastPage = response.data.meta.last_page;
              this.per_page = response.data.meta.per_page;
              this.currentPage = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.meta = response.data.meta;
            }

          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch your OKRs",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch your OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    formSubmitted() {
      const self = this;
      var validData = this.checkData(self.data_local);
      if(validData.status){
        const formData = new FormData(document.getElementById("okr-form"));
        formData.append("okr_details", JSON.stringify(self.data_local.okr_details));
        formData.append("period_range", self.data_local.period_range);
        formData.append("okr_type", self.data_local.okr_type);
        formData.append("okr_owner", self.data_local.okr_owner);
        formData.append("title", self.data_local.title);
        // formData.append("description", self.data_local.description);
        formData.append("parent_okr_type", self.data_local.parent_okr_type);
        formData.append("parent_okr", self.data_local.parent_okr);
        this.is_loading.submit = true;
        this.$store
          .dispatch("app-okrs/addOkr", formData)
          .then((res) => {
            this.is_loading.submit = false;
            this.$router.go();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Added Successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Added Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.getMyOkr();
          })
          .catch((error) => {
            this.is_loading.submit = false;
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Oops! OKR Adding Failed",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: error,
            //     },
            //   });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });

      }
      else{
        for(var key in validData.messages){
        //  self.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: validData.messages[key].title,
        //         text:validData.messages[key].text,
        //         icon: "BellIcon",
        //         variant: "danger",
        //       },
        //     }); 

        this.$toast({
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                icon: 'BellIcon',
                variant: 'danger',
                text: validData.messages[key].title,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    formUpdated() {
      const self = this;
      var validData = this.checkData(self.selectedOKR);
      if(validData.status){
        const formData = new FormData();
        formData.append("okr_details", JSON.stringify(self.selectedOKR.okr_details));
        formData.append("period_range", self.selectedOKR.period_range);
        formData.append("okr_type", self.selectedOKR.okr_type);
        formData.append("okr_owner", self.selectedOKR.okr_owner);
        formData.append("title", self.selectedOKR.title);
        // formData.append("description", self.selectedOKR.description);
        formData.append("parent_okr_type", self.selectedOKR.parent_okr_type);
        formData.append("parent_okr", self.selectedOKR.parent_okr_hashid);
        formData.append("_method", "PATCH");
        
        this.is_loading.submit = true;
        this.$swal({
          title: "Would you like to send OKR update emails to Users?",
          // text: `You want to delete.`,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Send",
          cancelButtonText: "Don't send",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            formData.append("sendNotifications", true);
          } else {
            formData.append("sendNotifications", false);
          }
          this.$store
          .dispatch("app-okrs/updateOkr", {
            id: self.selectedOKR.hashid,
            data: formData,
          })
          .then((res) => {
            this.is_loading.submit = false;
            this.selectedObjective={};
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$refs.update_goal.hide();
            this.$refs.goal_details.hide();
            this.$refs.edit_okr.hide();

            this.getMyOkr();
          })
          .catch((error) => {
            this.is_loading.submit = false;
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Oops! OKR updating Failed",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: error,
            //     },
            //   });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
        }); 
      }
      else{
        for(var key in validData.messages){
        //  self.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: validData.messages[key].title,
        //         text:validData.messages[key].text,
        //         icon: "BellIcon",
        //         variant: "danger",
        //       },
        //     }); 
        this.$toast({
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                icon: 'BellIcon',
                variant: 'danger',
                text: validData.messages[key].text,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-okrs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.okrStatusOption = res.data.meta.status;
          this.okr_settings = res.data.settings;
            for (let index = 0; index < this.okr_settings.length; index++) {
              const element = this.okr_settings[index];
              if(element.key == 'okr_range_type') {
                switch (element.value) {
                  case 'Monthly':                  
                    var today = new Date(); // get today's date
                    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // get first day of current month
                    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // get last day of current month
                  var start = startOfMonth.toISOString().substring(0, 10);
                  var end = endOfMonth.toISOString().substring(0, 10);
                    this.data_local.period_range = start+" to "+end;
                    this.keyResultDateConfig={
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      //inline: true,
                      mode: 'range',
                      enable: [
                      {
                          from: start,
                          to: end
                      }
                    ]
                    };

                    break;
                  case 'Quarterly':
                    var today = new Date();
                    const currentQuarter = Math.floor((today.getMonth() / 3)); // get current quarter
                    var startOfQuarter = new Date(today.getFullYear(), currentQuarter * 3, 2);
                    var endOfQuarter = new Date(today.getFullYear(), currentQuarter * 3 + 3, 1);
                    var start = startOfQuarter.toISOString().substring(0, 10);
                    var end = endOfQuarter.toISOString().substring(0, 10);
                    this.data_local.period_range = start+" to "+end;
                    this.keyResultDateConfig={
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      //inline: true,
                      mode: 'range',
                      config: [
                      {
                          from: start,
                          to: end
                      }
                    ]
                    };
                    break;

                  case 'Half yearly':
                  var today = new Date();
                  const currentMonth = today.getMonth(); // get current month
                  const startOfHalfYear = new Date(today.getFullYear(), currentMonth < 6 ? 0 : 6, 2);
                  const endOfHalfYear = new Date(today.getFullYear(), currentMonth < 6 ? 5 : 11, 31);
                  var start = startOfHalfYear.toISOString().substring(0, 10);
                  var end = endOfHalfYear.toISOString().substring(0, 10);
                    this.data_local.period_range = start+" to "+end;
                    this.keyResultDateConfig={
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      //inline: true,
                      mode: 'range',
                      enable: [
                      {
                          from: start,
                          to: end
                      }
                    ]
                    };
                  break;

                  case 'Yearly':
                    var today = new Date();
                    const startOfYear = new Date(today.getFullYear(), 0, 2);
                    const endOfYear = new Date(today.getFullYear(), 11, 32);
                    var start = startOfYear.toISOString().substring(0, 10);
                    var end = endOfYear.toISOString().substring(0, 10);
                    this.data_local.period_range = start+" to "+end;
                    this.keyResultDateConfig={
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      //inline: true,
                      mode: 'range',
                      enable: [
                      {
                          from: start,
                          to: end
                      }
                    ]
                    };
                  break;
                  default:
                    break;
                }
              } 
              if(element.key == 'organisation_okr_creators' ){
                if((element.value && element.value.includes(this.userDataLocal.email)) || this.$can('Zircly Admin') ){
                  this.okrTypeList = [
                    {
                      label: "Organizational OKR",
                      code: "Organizational OKR",
                    },
                    {
                      label: "Team OKR",
                      code: "Team OKR",
                    },
                    {
                      label: "Individual OKR",
                      code: "Individual OKR",
                    }
                  ];
                }
              }       
            }
            
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
             
       this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getUserPreviousOKRPendingObjectives(){
      const self = this;
      self.$store
        .dispatch("app-okrs/getUserPreviousOKRPendingObjectives")
        .then((res) => {
          self.items = res.data.data;
          self.$refs['addToCurrentMonth'].show();
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! unable to get pending objective from old records Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! unable to get pending objective from old records Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    addOnSelected() {
      this.selectedOKR.okr_details.push({
          'type':'Objective',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':0,
            'priority_level':0,
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
        })
      
    },
    add() {

      this.data_local.okr_details.push({
        
          'type':'Objective',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':0,
            'priority_level':0,
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
        })

      this.$toast.success("Empty Objective Added");
  this.$nextTick(() => {

    const inputFields = this.$refs.lastAddedInput;

    const lastInputIndex = inputFields.length-1;
    
    if (lastInputIndex > 0) {
      inputFields[lastInputIndex].focus();
    }
  });

 
 },
    copy(key) {
      this.data_local.okr_details.push(this.data_local.okr_details[key])
      
    },
    remove(index) {
      this.data_local.okr_details.splice(index, 1)
    },
    removeFromSelected(index) {
      this.selectedOKR.okr_details.splice(index, 1)
    },
    checkData(data) {
      var messages = [];

      var selectedDate = data.period_range;
      if (!selectedDate){
        messages.push({'title':'Oops! We need a Date range for OKR','text':'Please select Period Start and End Date'});
        response = {"status":false,'messages':messages};
        return response;        
      }
      var dates = selectedDate.split(" to ");
      if(dates.length != 2){
        messages.push({'title':'Oops! We need a Date range for OKR','text':'Please select Period Start and End Date'});
      }
      
      if(data.parent_okr_type && data.okr_type !="Organizational OKR" && ((data.parent_okr == '' || data.parent_okr == null || data.parent_okr == undefined) && (data.parent_okr_hashid == '' || data.parent_okr_hashid == null || data.parent_okr_hashid == undefined))){
        messages.push({'title':"Parent OKR is Required ",'text':"Please Select Parent to link this OKR with tree"});
      }
      data.okr_details.forEach(function(obj,key) { 
        key = key+1;
        if(obj.type == '' || obj.type == null || obj.type == undefined){
          messages.push({'title':"Type is Required for Objective "+key,'text':"Please Select Type for Objective "+key});
        }
        // if(obj.status !=0 && (obj.status == '' || obj.status == null || obj.status == undefined)){
        //   messages.push({'title':"Satus is Required for Objective "+key,'text':"Please Select Status for Objective "+key});
        // }
        if(obj.objective == '' || obj.objective == null || obj.objective == undefined){
          messages.push({'title':"Objective Title is Required for Objective "+key,'text':"Please Select objective title for Objective "+key});
        }
        if(obj.key_results.length == 0){
          messages.push({'title':"key Result is Required for Objective "+key,'text':"Please Enter atleast one Key Result for Objective "+key});
        }
        for (let index = 0; index < obj.key_results.length; index++) {
          if(obj.key_results[index].description == '' || obj.key_results[index].description == null || obj.key_results[index].description == undefined){
            messages.push({'title':"Please Enter Description",'text':"Description is required for Key Result #"+(index+1)});
          }
          if(obj.key_results[index].timeline == '' || obj.key_results[index].timeline == null || obj.key_results[index].timeline == undefined){
            messages.push({'title':"Please Select Timeline",'text':"Timeline is required for Key Result #"+(index+1)});
          }          
        }
        
        });

      var response = {"status":false,'messages':messages};
      if(messages.length == 0){
        var response = {"status":true,'messages':messages};
      }
      return response;      
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    AddObjective() {
      this.data_local.okr_details = this.selected;
      // for (let index = 0; index < this.selected.length; ++index) {
      //   this.data_local.okr_details.push(this.selected[index]);
      //   ;
      // };
      this.$refs['addToCurrentMonth'].hide();
      ;
    },
    isVisible(comment){
      if(comment.visiblity_type == 'Public'){
        return true;
      }else{
        if(comment.user.hashid == this.userDataLocal.hashid){
          return true;
        }
        if(this.selectedOKR.user.hashid == this.userDataLocal.hashid){
          return true;
        }
      }
    },
    addKeyResult(key,type){
      if(type == 'add'){
        this.data_local.okr_details[key].key_results.push(
          {
            'description' : '',
            'status':0,
            'priorty_level':0,
            'time_line':''
          }
        );
      }
      if(type == 'update'){
        this.selectedOKR.okr_details[key].key_results.push(
          {
            'description' : '',
            'status':0,
            'priorty_level':0,
            'time_line':''
          }
        );
      }
      this.$toast.success("Empty Key Result Added");
    },
    removeKeyResult(key,index,type){
      if(type == 'add'){
        this.data_local.okr_details[key].key_results.splice(index, 1)
      }
      if(type == 'update'){
        this.selectedOKR.okr_details[key].key_results.splice(index, 1)
      }
    },
    addSelectedKeyResult(){
        this.selectedObjective.key_results.push({
            'description' : '',
            'status':0,
            'priorty_level':0,
            'time_line':''
          });
    },
    removeSelectedKeyResult(index){
        this.selectedObjective.key_results.splice(index, 1)
    },

    getPriorityClass(status){
    if (status == 0) return "success";
    if (status == 1) return "warning";
    if (status == 2) return "danger";
    return "primary";
    },
    getPriorityLabel(status){
      var label = "Low";
      for(var key in this.priorityList){
        if(status == this.priorityList[key].code){
          label = this.priorityList[key].label
        }
      }
      return label
    },
    getObjectiveRatio(Objective){
      var count = Objective.key_results.length;
      var score = 0;
      Objective.key_results.forEach(element => {
        if(element.status == 2){
          score = score +1;
        }
        else if(element.status == 4){
          count = count-1;
        }
      });
      return "( "+score+" / "+count+" )";
    },
    getObjectiveStatus(Objective){
      var count = Objective.key_results.length;
      var countOfInProgress = 0;
      var countOfPending = 0;
      var countOfHold = 0;
      var countOfCancelled = 0;

      var score = 0;
      Objective.key_results.forEach(element => {
        if(element.status == 2){
          score = score +1;
        }
        else if(element.status == 1){
          countOfInProgress = countOfInProgress + 1;
        }
        else if(element.status == 0){
          countOfPending = countOfPending + 1;
        }
        else if(element.status == 3){
          countOfHold = countOfHold + 1;
        }
        else if(element.status == 4){
          countOfCancelled = countOfCancelled + 1;
          count = count-1;
        }
      });
      if(countOfPending == count){
        return 0;
      }else if(countOfInProgress == count){
        return 1;
      }else if(score == count){
        return 2;
      }else if(countOfHold == count){
        return 3;
      }else if(countOfCancelled == count){
        return 4;
      }
      var  percentage = (score / count) * 100;
      if(percentage > 0){
        return 1;
      }
      else if(percentage == 100){
        return 2;
      }
      else{
        return 0;
      }
    },
    rangeChanged(val){
      var start  = val.split(' to ')[0];
      var end  = val.split(' to ')[1];
      if(start && end){
        this.keyResultDateConfig={
          altInput: true,
          altFormat: "F j, Y",
          dateFormat: "Y-m-d",
          //inline: true,
          mode: 'range',
          enable: [
          {
              from: start,
              to: end
          }
        ]
        };
      }
    },

    parentTypeChanged(){
      if(this.selectedOKR.parent_okr){
        if(this.selectedOKR.parent_okr_type != this.selectedOKR.parent_okr.type){
          this.selectedOKR.parent_okr_hashid = '' ;
        }else{
          this.selectedOKR.parent_okr_hashid = this.selectedOKR.parent_okr.hashid ;
        }
      }else{
        this.selectedOKR.parent_okr_hashid = '' ;
      }
      this.getOKRbyType(this.selectedOKR.parent_okr_type,'update');
    },
    shareUrl(hash_id) {
      
      return window.location.origin + "/team-okrs?okr_hash_id=" + hash_id;
    },
    copyURL(hash_id){
      var url = window.location.origin + "/team-okrs?okr_hash_id=" + hash_id;
      navigator.clipboard.writeText(url);
      this.onCopy();
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    getOKRIcon(okr){
      if(!okr.okr_type){
        okr.okr_type = okr.type;
      }
      if(okr.okr_type == 'Individual OKR'){
        return okr.user.avatar;
      }
      else if(okr.okr_type == 'Team OKR'){
        return require('@/assets/images/icons/okr/okr-team.png');
      }
      else if(okr.okr_type == 'Organizational OKR'){
        return require('@/assets/images/icons/okr/okr-company.png');
      }
    },
    addTypeSelected(option){
      this.data_local.okr_type = option.code;
      if(option.code == 'Individual OKR'){
        this.data_local.title = this.userDataLocal.first_name+"'s OKR"; // Gunaseelan's OKR
      }else if(option.code == 'Team OKR' && this.userDataLocal.division){
        this.data_local.title = this.userDataLocal.division+" OKR"; // Zircly OKR
      }
    }
  },
  computed:{
    VisibleComments(){
        this.visibleComments = [];
      if(this.selectedOKR.comments){
        for(var index in this.selectedOKR.comments){
          if(this.isVisible(this.selectedOKR.comments[index])){
            this.visibleComments.push(this.selectedOKR.comments[index]);
          }
        }
      }
      return this.visibleComments;
    },
    getObjectives(){
      this.objectives = [];
      if(this.selectedOKR){
        for(var key in this.selectedOKR.okr_details){
          if(this.selectedOKR.okr_details[key].type == 'Objective'){
            this.objectives.push(this.selectedOKR.okr_details[key]);
          }
        }
        return this.objectives;
      }
      return this.objectives;
    },
    getExperiments(){
      this.experiments = [];
      if(this.selectedOKR){
        for(var key in this.selectedOKR.okr_details){
          if(this.selectedOKR.okr_details[key].type == 'Experiment'){
            this.experiments.push(this.selectedOKR.okr_details [key]);
          }
        }
        return this.experiments;
      }
      return this.experiments;
    },
    
  }
};
$(document).ready(function () {
    $('.flatpickr-day.selected.endRange').prev('span').addClass('active');
});
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
